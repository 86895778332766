<template>
    <div :class="[s.mainContainer, theme.profile.profile]">
        <div :class="s.subRow">
            <Icon icon="user" :class="s.icon" />
            <div>
                <div :class="s.row">
                    <p :class="[s.mbS, s.nameText]">
                        {{ userName ? userName : '...' }}
                    </p>
                    <Guid
                        :guid="userGuid"
                        :class="s.mbS"
                        copied-value-name="User ID"
                    />
                </div>
                <p :class="[theme.profile.text, s.emailText]">
                    {{ userEmail ? userEmail : '...' }}
                </p>
            </div>
            <div :class="s.grid">
                <div :class="s.col">
                    <span :class="s.subHeaderText">
                        Last Login
                    </span>
                    <span :class="s.contentText">
                        {{ lastLoginParsedDate }}
                    </span>
                </div>
                <div :class="s.col">
                    <span :class="s.subHeaderText">
                        From IP
                    </span>
                    <span :class="s.contentText">
                        {{ lastLoginIpAddress }}
                    </span>
                </div>
            </div>
        </div>
        <div :class="[s.subRow, s.border]">
            <SettingsQuotationAsset />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Icon from 'UI/Icon.vue';
import Guid from 'UI/Guid.vue';
import theme from 'Theme';
import SettingsQuotationAsset from 'Components/Profile/components/SettingsQuotationAsset.vue';

export default {
    name: 'ProfileRow',
    components: {
        SettingsQuotationAsset,
        Icon,
        Guid,
    },
    data() {
        return {
            theme,
        };
    },
    computed: {
        ...mapGetters({
            userEmail: 'Auth/profileEmail',
            userName: 'Auth/profileName',
            userGuid: 'Auth/userGuid',
            lastLoginDate: 'Auth/History/lastLoginDate',
            lastLoginIpAddress: 'Auth/History/lastLoginIpAddress',
            lastLogin: 'Auth/History/lastLogin',
        }),
        lastLoginParsedDate() {
            return this.$store.getters.getTimeDateString({ timestamp: this.lastLoginDate, showTime: false });
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        ownedAccountsCount() {
            return this.$store.state.Accounts.accounts.filter((a) => a.isOwned && a.isActive).length - 1;
        },
        managedAccountsCount() {
            return this.$store.state.Accounts.accounts.filter((a) => !a.isOwned && a.isActive).length;
        },
        requestsQuota() {
            if (this.$store.state.User.currentUser) {
                return this.$store.state.User.currentUser.availableQuota;
            }
            return 0;
        },
    },
};
</script>

<style lang="postcss" module="s">
.mainContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: var(--cl-white);
    padding: 0 var(--m-m);
    & .subRow {
        display: flex;
        align-items: center;
        column-gap: var(--m-xl);
        & .icon {
            width: 58px;
            height: 58px;
        }
        & .mbS {
            margin-bottom: var(--m-xs);
        }
        & .center {
            align-self: center;
        }
    }
    & .row {
        display: flex;
        align-items: center;
        column-gap: var(--m-s);
    }
    & .nameText {
        font-size: var(--fs-xxxl);
        font-weight: var(--fw-bold);
        color: var(--cl-black);
    }
    & .emailText {
        font-size: var(--fs-m);
        font-weight: var(--fw-regular);
        color: var(--cl-black);
    }
    & .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-right: 1px solid var(--cl-gray-light);
        border-left: 1px solid var(--cl-gray-light);
        height: 100%;

        & .col {
            display: flex;
            flex-direction: column;
            row-gap: var(--m-s);
            padding: var(--m-l);

            & .subHeaderText {
                font-size: var(--fs-s);
                font-weight: var(--fw-semibold);
                color: var(--cl-gray);
            }
            & .contentText {
                font-size: var(--fs-l);
                font-weight: var(--fw-semibold);
                color: var(--cl-black);
            }
        }
    }

    & .border {
        border-left: 1px solid var(--cl-gray-light);
        padding: var(--m-l);
    }
}
</style>
