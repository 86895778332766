import { render, staticRenderFns } from "./Settings.vue?vue&type=template&id=910f3092&"
import script from "./Settings.vue?vue&type=script&lang=ts&"
export * from "./Settings.vue?vue&type=script&lang=ts&"
import style0 from "./Settings.vue?vue&type=style&index=0&id=910f3092&prod&lang=postcss&module=s&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports