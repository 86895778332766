<template>
    <div :class="s.container">
        <div :class="s.header">
            <slot name="tabs"></slot>
        </div>
        <div :class="s.relative">
            <table :class="[theme.table.table, s.w100]">
                <thead>
                <tr>
                    <th :class="[theme.table.alignLeft, theme.table.headerText]">Time & Date</th>
                    <th :class="[theme.table.alignCenter, theme.table.headerText]">Device</th>
                    <th :class="[theme.table.alignCenter, theme.table.headerText]">IP-Address</th>
                    <th :class="[theme.table.alignCenter, theme.table.headerText]">Location</th>
                    <th :class="[theme.table.alignCenter, theme.table.headerText]">Action</th>
                    <th :class="[theme.table.alignRight, theme.table.headerText]">Status</th>
                </tr>
                </thead>
                <AuthEventRow
                    v-for="(authEvent, index) in paginatedEvents"
                    :key="`authEvent${index}`"
                    :auth-event="authEvent"
                />
            </table>
        </div>
        <div :class="s.pagination">
            <Pagination
                :page="page"
                :pages-quantity="totalPages"
                @change="page = $event"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import theme from 'Theme';
import Pagination from 'Control/Pagination.vue';

import AuthEventRow from './AuthEventRow.vue';

export default {
    name: 'AuthEvents',
    components: {
        AuthEventRow,
        Pagination,
    },
    data() {
        return {
            theme,
            perPage: 11,
            page: 1,
        };
    },
    computed: {
        ...mapGetters({
            authEvents: 'Auth/History/list',
        }),
        totalPages() {
            return Math.ceil(this.authEvents.length / this.perPage);
        },
        paginatedEvents() {
            return this.authEvents.filter((_, index) => index >= (this.page - 1) * this.perPage && index < this.perPage * this.page);
        },
    },
    async mounted() {
        await this.$store.dispatch('Auth/History/getAllItems');
    },
};
</script>

<style lang="postcss" module="s">
.container {
    width: 100%;
    height: 100%;
    background-color: var(--cl-white);
    border-radius: 8px;
    overflow-y: hidden;
    & .header {
        padding: var(--m-m);
        border-bottom: 1px solid var(--cl-gray-light);
    }
    & .w100 {
        width: 100%;
    }
    & tr {
        height: 44px;
    }
    & .relative {
        position: relative;
    }
    & .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 4px;
        width: 100%;
    }
}
</style>
