// This file was autogenerated. Please do not change.
/* A payload to update quota */
export interface IUpdateQuotaPayload {
    quota: number;
}

export default class UpdateQuotaPayload {
    readonly _quota: number;

    /** Description: Quota to add */
    get quota(): number {
        return this._quota;
    }

    constructor(props: IUpdateQuotaPayload) {
        this._quota = props.quota;
    }

    serialize(): IUpdateQuotaPayload {
        const data: IUpdateQuotaPayload = {
            quota: this._quota,
        };
        return data;
    }

    toJSON(): IUpdateQuotaPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            quota: typeof this._quota === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
