
import Vue from 'vue';
import { mapGetters } from 'vuex';

import theme from 'Theme';
import { getExpectedRebates, getPaidRebates, getTotalExpectedRebates, getTotalPaidRebates } from 'Store/v2/Rebates';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import { Account } from 'Models/accounts';
import { Placement } from 'Models/placements';
import Icon from 'UI/Icon.vue';
import PaidRebate from 'Entities/privatePresenter/PaidRebate';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import NoData from 'UI/NoData.vue';

type Data = {
    theme: any;
    showHistory: boolean;
    activeHistoryPlacement: string;
};

type Methods = {
    flip: () => void;
    getAccountColor: (data: string) => string;
    getAccountName: (data: string) => string;
    getDateString: (data: string) => string;
};

type Computed = {
    isThemeDark: boolean;
    quotationAssetSymbol: string;
    quotationAssetCharacter: string;
    accounts: Account[];
    accountsArrayLength: number;
    totalPaidRebates: number;
    totalExpectedRebates: number;
    quotationAssetPrecision: number;
    nextPaymentDate: string;
    expectedRebatesTableData: [string, number][];
    placements: Placement[];
    historyPlacements: string[];
    paidRebates: PaidRebate[];
};

export default Vue.extend<Data, Methods, Computed>({
    components: { NoData, AccountColorMarker, Icon },
    data() {
        return {
            theme,
            showHistory: false,
            activeHistoryPlacement: 'All',
        };
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            accounts: 'Accounts/accounts',
            placements: 'Placements/placements',
        }),
        accountsArrayLength() {
            return this.accounts.length;
        },
        totalPaidRebates() {
            return this.$store.state.Rebates.totalPaidRebates;
        },
        totalExpectedRebates() {
            return this.$store.state.Rebates.totalExpectedRebates;
        },
        quotationAssetPrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
        nextPaymentDate() {
            const currentDate = new Date();

            currentDate.setUTCHours(6);
            currentDate.setUTCMinutes(0);
            currentDate.setUTCSeconds(0);
            currentDate.setUTCMilliseconds(0);

            if (new Date().getUTCHours() >= 6) {
                currentDate.setUTCDate(currentDate.getUTCDate() + 1);
            }

            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            }).format(currentDate);
        },
        expectedRebatesTableData() {
            const placementsList = this.placements.reduce((accum: string[], current) => {
                if (!current.disabled && (current.type === 'crypto-spot' || current.type === 'crypto-futures')) {
                    return [...accum, current.name];
                }
                return accum;
            }, []);
            const resultMap = this.$store.state.Rebates.expectedRebates;
            placementsList.forEach((p) => {
                if (!resultMap.has(p)) {
                    resultMap.set(p, 0);
                }
            });
            return Array
                .from(resultMap)
                .sort((a, b) => (b as [string, number])[1] - (a as [string, number])[1]) as [string, number][];
        },
        historyPlacements() {
            return this.placements.reduce((accum: string[], current) => {
                if (!current.disabled && (current.type === 'crypto-spot' || current.type === 'crypto-futures')) {
                    return [...accum, current.name];
                }
                return accum;
            }, ['All']);
        },
        paidRebates() {
            return this.$store.state.Rebates.paidRebates.filter(({ placementName }) => {
                if (this.activeHistoryPlacement === 'All') {
                    return true;
                }
                return placementName === this.activeHistoryPlacement;
            });
        },
    },
    methods: {
        flip() {
            (this.$refs.rebates as any).classList.add('flipBlock');
            setTimeout(() => {
                (this.$refs.rebates as any).classList.remove('flipBlock');
            }, 500);
            this.showHistory = !this.showHistory;
        },
        getAccountColor(accountId) {
            const account = this.$store.state.Accounts.accounts.find((a) => a.id === accountId);
            if (!account.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return account.color;
        },
        getAccountName(accountId) {
            return this.$store.state.Accounts.accounts
                .find((a) => a.id === accountId)?.name ?? '';
        },
        getDateString(dateStr) {
            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            }).format(new Date(dateStr));
        },
    },
    async mounted() {
        await this.$store.dispatch(getTotalPaidRebates(undefined));
        await this.$store.dispatch(getTotalExpectedRebates(undefined));
        await this.$store.dispatch(getExpectedRebates(undefined));
        await this.$store.dispatch(getPaidRebates(undefined));
    },
    watch: {
        async quotationAssetSymbol() {
            await this.$store.dispatch(getTotalPaidRebates(undefined));
            await this.$store.dispatch(getTotalExpectedRebates(undefined));
            await this.$store.dispatch(getExpectedRebates(undefined));
            await this.$store.dispatch(getPaidRebates(undefined));
        },
        async accountsArrayLength() {
            await this.$store.dispatch(getTotalPaidRebates(undefined));
            await this.$store.dispatch(getTotalExpectedRebates(undefined));
            await this.$store.dispatch(getExpectedRebates(undefined));
            await this.$store.dispatch(getPaidRebates(undefined));
        },
    },
});
