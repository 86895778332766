
import Vue from 'vue';

import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import { initSDK } from 'Store/v2/KYC';
import theme from 'Theme';
import Icon from 'UI/Icon.vue';

interface Methods {
    startKyc: () => void;
}

interface Computed {
    currentUser: InternalUserResponse;
    status: string;
    isKycVerified: boolean;
}

export default Vue.extend<any, Methods, Computed>({
    name: 'Settings',
    components: { Icon },
    data() {
        return {
            theme,
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        status() {
            if (this.currentUser) {
                return this.currentUser.type.toUpperCase() === 'CORPORATE' ? 'Corporate' : 'Individual';
            }
            return 'Individual';
        },
    },
    methods: {
        async startKyc() {
            await this.$store.dispatch(initSDK());
        },
    },
});
