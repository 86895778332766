<template>
    <div
        :class="s.container"
        v-click-outside="hideDropdown"
    >
        <div
            :class="s.dropdownContainer"
            @click="isDropdownOpen = !isDropdownOpen"
        >
            <span :class="[theme.profile.text, theme.profile.big, theme.profile.bold, theme.profile.gray]">
                Default account currency
            </span>
            <div :class="s.dropdownRow">
                <Cryptoicon
                    :symbol="quotationAssetSymbol"
                    :size="17"
                />
                <span :class="[theme.profile.text, theme.profile.big, theme.profile.extrabold, theme.profile.black]">
                    {{ $store.getters['localization'].AssetsDescriptions[quotationAssetSymbol] }}
                </span>
                <span :class="[theme.profile.text, theme.profile.big, theme.profile.regular, theme.profile.violet]">
                    / {{ quotationAssetSymbol }}
                </span>
                <Icon icon="arrow_down" />
            </div>
        </div>
        <ul
            :class="s.dropdown"
            v-if="isDropdownOpen"
        >
           <li
               :class="s.item"
               v-for="asset in quotationAssets"
               :key="asset.symbol"
               @click="setQuotationAsset(asset.symbol)"
           >
               <p :class="s.dropdownRow">
                   <CryptoIcon :symbol="asset.symbol" />
                   <span :class="[theme.profile.text, theme.profile.big, theme.profile.extrabold, theme.profile.black]">
                       {{ $store.getters['localization'].AssetsDescriptions[asset.symbol] }}
                   </span>
                   <span :class="[theme.profile.text, theme.profile.big, theme.profile.regular, theme.profile.violet]">
                       / {{ asset.symbol }}
                   </span>
               </p>
           </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { quotationAssets, setQuotationAsset } from 'Store/v2/Assets';
import Icon from 'UI/Icon.vue';
import theme from 'Theme';

export default {
    name: 'SettingsQuotationAsset',
    components: {
        Icon,
    },
    data() {
        return {
            quotationAssets,
            theme,
            isDropdownOpen: false,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
    },
    methods: {
        hideDropdown() {
            this.isDropdownOpen = false;
        },
        async setQuotationAsset(assetSymbol) {
            await this.$store.dispatch(setQuotationAsset(assetSymbol));
            await this.$store.dispatch('Placements/updatePlacementsList');
            this.hideDropdown();
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    position: relative;
    margin-left: auto;
    order: 3;
    & .dropdownRow {
        display: flex;
        align-items: center;
        column-gap: var(--m-xs);
    }
    & .dropdownContainer {
        cursor: pointer;
    }
    & .dropdown {
        position: absolute;
        top: calc(100% + 4px);
        background-color: var(--cl-white);
        min-width: 100%;
        right: 0;
        border-radius: 8px;
        box-shadow: 0 0 9px rgba(0, 0, 0, 0.1), 0 6px 15px rgba(35, 36, 77, 0.1);
        overflow: hidden;
        & .item {
            padding: var(--m-s) var(--m-m);
            background-color: var(--cl-white);
            transition: background-color var(--transition);
            cursor: pointer;
            white-space: nowrap;
            &:hover {
                background-color: var(--cl-violet-light);
            }
        }
    }
}
</style>
