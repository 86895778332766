
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import { useAction } from 'Store';
import KeyResponse from 'Lib/entities/apiManagement/KeyResponse';
import { UserActions } from '@/store/v2/Users';
import { getAddresses, SET_LAST_ROUTE } from 'Store/v2/Addresses';
import ApiManagementApi from 'Apis/ApiManagement';
import UpdateQuotaPayload from 'Entities/apiManagement/UpdateQuotaPayload';
import theme from 'Theme';
import SwitchControl from 'Control/Switch.vue';
import { getGroups } from 'Store/v2/Groups';
import { pluralize } from 'Lib/utils/pluralize';

interface Data {
    isMfaProcessing: boolean;
    isMfaCheckedValue: boolean;
    isMFASwitchDisabled: boolean;
    pluralize: typeof pluralize;
}

interface Methods {
    turnOffMFA: () => void;
    addQuota: () => void;
    openManageKeysPage: () => void;
    openGroups: () => void;
    toggleMFA: (data: boolean) => void;
}

interface Computed {
    isMfaChecked: boolean;
    isMfaEnabled: boolean;
    MFAtype: typeof MFA_ENROLL_FACTOR_TYPES.TOTP | undefined;
    apiKeys: KeyResponse[];
    currentUserQuota: number | undefined;
    currentUser: any;
    status: string;
    addressesCount: number;
    assetsCount: number;
    groupsCount: number;
}

export default Vue.extend<Data, Methods, Computed>({
    name: 'AuthInfo',
    components: { SwitchControl },
    data() {
        return {
            isMfaProcessing: false,
            isMfaCheckedValue: true,
            isMFASwitchDisabled: false,
            theme,
            requisites: [],
            SET_LAST_ROUTE,
            pluralize,
        };
    },
    computed: {
        ...mapGetters({
            userEmail: 'Auth/profileEmail',
        }),

        isMfaChecked: {
            get() {
                return this.isMfaCheckedValue;
            },
            set(value) {
                this.isMfaCheckedValue = value;
                if (!value) { this.turnOffMFA(); }
            },
        },
        isMfaEnabled() {
            return this.$store.getters['Auth/isMfaEnabled'];
        },
        MFAtype() {
            return this.isMfaEnabled ? MFA_ENROLL_FACTOR_TYPES.TOTP : undefined;
        },
        apiKeys() {
            return this.$store.state.ApiManagment.apiKeys;
        },
        currentUserQuota() {
            return this.$store.state.User.currentUser?.availableQuota;
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        status() {
            if (this.currentUser) {
                return this.currentUser.type.toUpperCase() === 'CORPORATE' ? 'Corporate' : 'Individual';
            }
            return 'Individual';
        },
        addressesCount() {
            return this.$store.state.Addresses.addresses.length;
        },
        assetsCount() {
            const set = new Set<string>();
            this.$store.state.Addresses.addresses.forEach(({ assetSymbols }) => {
                (assetSymbols || []).forEach((s) => {
                    set.add(s);
                });
            });
            return set.size;
        },
        groupsCount() {
            return this.$store.state.Groups.groups?.length ?? 0;
        },
    },
    async mounted() {
        await this.$store.dispatch(getGroups(undefined));
        await this.$store.dispatch(useAction('User', UserActions.getCurrentUser));
        await this.$store.dispatch(getAddresses(undefined));
        await this.$store.dispatch(getGroups(undefined));

        // const { data: requisites } = await WalletsApi.listUserBankRequisites();
        // this.requisites = requisites;
    },
    methods: {
        toggleMFA(isEnabled) {
            this.isMFASwitchDisabled = true;
            if (isEnabled) {
                this.$router.push('/profile/settotpmfa');
            } else {
                this.turnOffMFA();
            }
        },
        async addQuota() {
            if (!this.isMfaEnabled) {
                await this.$store.dispatch('Notificator/showErrorNotification', 'Please turn on two factor authentication to add requests');
                return;
            }
            const { data: res } = await ApiManagementApi.updateQuota(new UpdateQuotaPayload({
                quota: 10000,
            }));
            this.$store.commit('User/SET_NEW_QUOTA', res.quota);
        },
        async turnOffMFA() {
            if (this.isMfaProcessing) {
                return false;
            }

            const startChallengePath = this.$route.path;

            this.isMfaProcessing = true;
            this.isMfaChecked = false;

            try {
                await this.$store.dispatch('Auth/disableMfaChallenge', {
                    mfaType: this.MFAtype ?? '',
                    returnToStart: true,
                });
                await this.$store.dispatch('Notificator/showSuccessNotification', 'MFA is successfully disabled');
            } catch ({ message }) {
                this.isMfaChecked = true;
                await this.$store.dispatch('Notificator/showErrorNotification', message);
            } finally {
                this.isMfaProcessing = false;
                await this.$router.replace(startChallengePath);
            }
        },
        async openManageKeysPage() {
            if (!this.isMfaEnabled) {
                await this.$store.dispatch('Notificator/showErrorNotification', 'Please turn on two factor authentication to manage API-Keys');
                return;
            }
            await this.$router.push('/profile/manageApiKeys');
        },
        async openGroups() {
            if (!this.isMfaEnabled) {
                await this.$store.dispatch('Notificator/showErrorNotification', 'Please turn on two factor authentication to manage institutional groups');
                return;
            }
            await this.$router.push('/profile/groups-list');
        },
    },
});
