<template>
    <div>
        <PageLayout>
            <template #desktop-view>
                <grid-layout
                    :layout.sync="layout"
                    :col-num="3"
                    :row-height="90"
                    :responsive="true"
                    :breakpoints="vueGridLayoutBreakpoints"
                    :cols="{
                        lg: 3,
                        md: 3,
                    }"
                    :is-draggable="false"
                    :is-resizable="false"
                    :vertical-compact="true"
                    :margin="[14, 14]"
                    :use-css-transforms="true"
                    @breakpoint-changed="breakpointChangedEvent"
                >
                    <grid-item
                        :x="getLayoutElementByName(layout, 'AuthInfo').x"
                        :y="getLayoutElementByName(layout, 'AuthInfo').y"
                        :w="getLayoutElementByName(layout, 'AuthInfo').w"
                        :h="getLayoutElementByName(layout, 'AuthInfo').h"
                        :i="getLayoutElementByName(layout, 'AuthInfo').i"
                        drag-allow-from=".module-header"
                    >
                        <AuthInfo />
                    </grid-item>
                    <grid-item
                        :x="getLayoutElementByName(layout, 'Rebates').x"
                        :y="getLayoutElementByName(layout, 'Rebates').y"
                        :w="getLayoutElementByName(layout, 'Rebates').w"
                        :h="getLayoutElementByName(layout, 'Rebates').h"
                        :i="getLayoutElementByName(layout, 'Rebates').i"
                        drag-allow-from=".module-header"
                    >
                        <Rebates />
                    </grid-item>
                    <grid-item
                        :x="getLayoutElementByName(layout, 'Packages').x"
                        :y="getLayoutElementByName(layout, 'Packages').y"
                        :w="getLayoutElementByName(layout, 'Packages').w"
                        :h="getLayoutElementByName(layout, 'Packages').h"
                        :i="getLayoutElementByName(layout, 'Packages').i"
                        drag-allow-from=".module-header"
                    >
                        <Packages />
                    </grid-item>
                    <grid-item
                        :x="getLayoutElementByName(layout, 'History').x"
                        :y="getLayoutElementByName(layout, 'History').y"
                        :w="getLayoutElementByName(layout, 'History').w"
                        :h="getLayoutElementByName(layout, 'History').h"
                        :i="getLayoutElementByName(layout, 'History').i"
                        drag-allow-from=".module-header"
                    >
                        <History />
                    </grid-item>
                    <grid-item
                        :x="getLayoutElementByName(layout, 'Settings').x"
                        :y="getLayoutElementByName(layout, 'Settings').y"
                        :w="getLayoutElementByName(layout, 'Settings').w"
                        :h="getLayoutElementByName(layout, 'Settings').h"
                        :i="getLayoutElementByName(layout, 'Settings').i"
                    >
                        <Settings />
                    </grid-item>
                    <grid-item
                        :x="getLayoutElementByName(layout, 'ProfileRow').x"
                        :y="getLayoutElementByName(layout, 'ProfileRow').y"
                        :w="getLayoutElementByName(layout, 'ProfileRow').w"
                        :h="getLayoutElementByName(layout, 'ProfileRow').h"
                        :i="getLayoutElementByName(layout, 'ProfileRow').i"
                    >
                        <ProfileRow />
                    </grid-item>
                </grid-layout>
            </template>
        </PageLayout>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PageLayout from 'Common/PageLayout.vue';
import { gridPages } from 'Models/interface';
import Settings from 'Components/Profile/components/Settings.vue';
import ProfileRow from 'Components/Profile/components/ProfileRow.vue';
import AuthInfo from 'Components/Profile/components/AuthInfo.vue';
import History from 'Components/Profile/components/History.vue';
import { getApiKeys } from 'Store/v2/ApiManagment';
import layoutNameResolver from 'Mixins/layoutNameResolver';
import Packages from 'Components/Profile/components/Packages.vue';
import Rebates from 'Components/Profile/components/Rebates.vue';

export default {
    name: 'Profile',
    mixins: [
        layoutNameResolver,
    ],
    components: {
        Rebates,
        Packages,
        PageLayout,
        Settings,
        ProfileRow,
        AuthInfo,
        History,
    },
    data() {
        return {
            currentBreakpoint: 'lg',

            activeMobileLayoutPage: 0,
        };
    },
    computed: {
        ...mapGetters({
            vueGridLayoutBreakpoints: 'vueGridLayoutBreakpoints',
            vueGridLayoutCols: 'vueGridLayoutCols',
        }),
        layout: {
            get() {
                return this.$store.getters.pageGrid(gridPages.PROFILE, this.currentBreakpoint);
            },
            set(grid) {
                this.$store.dispatch('changePageGrid', { page: gridPages.PROFILE, grid, breakpoint: this.currentBreakpoint });
            },
        },
    },
    mounted() {
        this.$store.dispatch(getApiKeys());
    },
    methods: {
        breakpointChangedEvent(breakpoint) {
            this.currentBreakpoint = breakpoint;
        },
    },
};
</script>
