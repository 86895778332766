
import Vue from 'vue';
import { mapGetters } from 'vuex';

import theme from 'Theme';
import Button from 'Control/Button.vue';
import Icon from 'UI/Icon.vue';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import Account from 'Entities/privatePresenter/Account';
import Group from 'Entities/privatePresenter/Group';
import { composedPath } from 'Lib/utils/eventPathChecker';

type Data = {
    showOwnedAccountsModal: boolean;
    showManagedAccountsModal: boolean;
    showInstitutionalModal: boolean;
    MILLISECONDS_IN_MONTH: number;
    theme: any;
};

type Methods = {
    getAccountsColor: (data: Account) => string;
    toggleModal: (data: 'owned' | 'managed' | 'institutional') => void;
    closeModal: (data: any) => void
    openSubscriptions: (data: string) => void;
    goToPurchase: () => void;
};

type Computed = {
    isThemeDark: boolean;
    ownedAccountsCount: number;
    managedAccountsCount: number;
    institutionalGroupsCount: number;
    ownedAccountsList: Account[];
    managedAccountsList: Account[];
    institutionalList: Group[];
    nextPaymentDate: string;
    currentUserQuota: string | number;
};

export default Vue.extend<Data, Methods, Computed>({
    components: { AccountColorMarker, Icon, Button },
    data() {
        return {
            showOwnedAccountsModal: false,
            showManagedAccountsModal: false,
            showInstitutionalModal: false,

            MILLISECONDS_IN_MONTH: 2.628e+9,
            theme,
        };
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
        }),
        ownedAccountsCount() {
            return this.$store.getters['Accounts/ownAccounts'].length;
        },
        managedAccountsCount() {
            return this.$store.getters['Accounts/manageAccounts'].length;
        },
        institutionalGroupsCount() {
            return this.$store.state.Groups.groups.length;
        },
        ownedAccountsList() {
            const accounts: Account[] = this.$store.getters['Accounts/ownAccounts'];
            accounts.sort(({ name: a }, { name: b }) => {
                if (a === 'main') {
                    return -1;
                }
                if (b === 'main') {
                    return 1;
                }
                return 0;
            });
            return accounts;
        },
        managedAccountsList() {
            return this.$store.getters['Accounts/manageAccounts'];
        },
        institutionalList() {
            return this.$store.state.Groups.groups;
        },
        nextPaymentDate() {
            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
            }).format(new Date(Date.now() + this.MILLISECONDS_IN_MONTH));
        },
        currentUserQuota() {
            return this.$store.state.User.currentUser?.availableQuota?.getSeparatedDigits() ?? 0;
        },
    },
    methods: {
        getAccountsColor(account: Account) {
            if (!account || !account.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return account.color;
        },
        toggleModal(type) {
            switch (type) {
                case 'owned': {
                    if (this.ownedAccountsList.length > 2) {
                        this.showOwnedAccountsModal = !this.showOwnedAccountsModal;
                        this.showManagedAccountsModal = false;
                        this.showInstitutionalModal = false;
                    }
                    break;
                }
                case 'managed': {
                    if (this.managedAccountsList.length > 1) {
                        this.showManagedAccountsModal = !this.showManagedAccountsModal;
                        this.showInstitutionalModal = false;
                        this.showOwnedAccountsModal = false;
                    }
                    break;
                }
                case 'institutional': {
                    if (this.institutionalList.length > 1) {
                        this.showInstitutionalModal = !this.showInstitutionalModal;
                        this.showOwnedAccountsModal = false;
                        this.showManagedAccountsModal = false;
                    }
                    break;
                }
            }
        },
        closeModal(e) {
            const path = composedPath(e.target);
            if (!path.some((node) => node.classList && Array.from(node.classList).includes('modal-toggler'))) {
                this.showOwnedAccountsModal = false;
                this.showManagedAccountsModal = false;
                this.showInstitutionalModal = false;
            }
        },
        async openSubscriptions(tab) {
            await this.$router.push({
                path: '/profile/subscriptions',
                query: {
                    tab,
                },
            });
        },
        async goToPurchase() {
            await this.$router.push('/profile/purchase');
        },
    },
    mounted() {
        document.addEventListener('click', this.closeModal);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeModal);
    },
});
