<template>
    <tr :class="s.row">
        <td :class="[theme.profile.text, theme.profile.big, theme.profile.bold, theme.profile.gray]">
            {{ date }}
        </td>
        <td :class="[theme.profile.text, theme.profile.big, theme.profile.bold, theme.profile.gray]">
            {{ device }}
        </td>
        <td :class="[theme.profile.text, theme.profile.big, theme.profile.bold, theme.profile.black]">
            {{ ipAddress }}
        </td>
        <td :class="[theme.profile.text, theme.profile.big, theme.profile.bold, theme.profile.black]">
            {{ location }}
        </td>
        <td :class="[theme.profile.text, theme.profile.big, theme.profile.bold, theme.profile.black]">
            Start Session
        </td>
        <td :class="[theme.profile.text, theme.profile.big, theme.profile.bold, theme.profile.green]">
            Success
        </td>
    </tr>
</template>

<script>
import { EventTypes, EventResponses } from 'Models/authEvents';
import theme from 'Theme';

export default {
    name: 'AuthEventRow',
    props: {
        authEvent: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            EventTypes,
            EventResponses,
            theme,
        };
    },
    computed: {
        date() {
            const date = new Date(this.authEvent.published);
            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            }).format(date);
        },
        device() {
            const { os, browser } = this.authEvent.client.userAgent;
            return `${os}, ${browser}`;
        },
        ipAddress() {
            return this.authEvent.client.ipAddress;
        },
        location() {
            const { country, city } = this.authEvent.client.geographicalContext;
            return `${country}, ${city}`;
        },
    },
};
</script>

<style lang="postcss" module="s">
.row {
    & td {
        text-align: center;
        &:first-child {
            text-align: left;
        }
        &:last-child {
            text-align: right;
        }
    }
}
</style>
