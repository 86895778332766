<template>
    <AuthEvents :class="theme.profile.profile" v-if="isMainTabActive">
        <template #tabs>
            <span :class="[theme.profile.text, theme.profile.large, theme.profile.extrabold, theme.profile.black]">
                History
            </span>
        </template>
    </AuthEvents>
</template>

<script>
import theme from 'Theme';

import AuthEvents from './History/AuthEvents.vue';

export default {
    name: 'History',
    components: {
        AuthEvents,
    },
    data() {
        return {
            activeTabIndex: 0,
            theme,
        };
    },
    computed: {
        isMainTabActive() {
            return this.activeTabIndex === 0;
        },
        isTransactionsTabActive() {
            return this.activeTabIndex === 1;
        },

    },
    methods: {
        setActiveTabIndex(index) {
            this.activeTabIndex = index;
        },
    },
};
</script>
